import {type BroadcastEventCallbackArgs} from '@app/index'

import {sendToast, closeToast} from '@app/utils/toast'
import ziggy from '@app/utils/ziggy'
import {router} from '@inertiajs/react'

export default [
  {
    name: '.delivery.status-updated',
    callback: ({
      delivery,
      notify,
    }: BroadcastEventCallbackArgs<{delivery: Delivery}>) => {
      sendToast({
        id: `delivery-${delivery.id}-toast`,
        duration: 30000,
        type: 'success',
        title: 'Solicitud de entrega a domicilio',
        description: `El usuario ${delivery.customer.name} ha solicitado una entrega a domicilio para ${delivery.packages_count} paquetes`,
        action: {
          label: 'Ver solicitud',
          onClick: () => {
            router.get(ziggy('deliveries.edit', {delivery: delivery.id}))

            closeToast(`delivery-${delivery.id}-toast`)
          },
        },
      })

      notify(
        `Nueva solicitud de entrega a domicilio para ${delivery.packages_count} paquetes`
      )
    },
  },
] as const
