import {Styles} from '@chakra-ui/theme-tools'

export default {
  global: {
    body: {
      backgroundColor: 'neutral.100',
      minHeight: 'h-screen',
    },
    html: {
      scrollBehavior: 'smooth',
    },
  },
} as Styles
