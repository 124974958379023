import {Center, Show, Stack} from '@chakra-ui/react'

import NavigationItem from './NavigationItem'
import useNavigationItemsData from './hooks/use-navigation-items-data.hook'
import useAuth from '@app/hooks/use-auth.hook'
import {memo} from 'react'
import Logo from '../Logo'

interface NavigationProps {
  onSelected?: () => void
}

export default memo(function Navigation({onSelected}: NavigationProps) {
  const items = useNavigationItemsData()
  const {isAgencyOwner, isAdminDashboard} = useAuth()

  if (!isAgencyOwner && !isAdminDashboard) {
    return null
  }

  return (
    <Stack
      minWidth="280px"
      maxWidth="280px"
      insetBlockStart="4"
      justifyContent="center"
      position="sticky"
      gap="4"
    >
      <Show above="xl">
        <Center
          marginInline="auto"
          borderBlockEnd="1px"
          borderColor="gray.200"
          width="40"
          height="16"
          justifyContent="center"
        >
          <Logo height="8" />
        </Center>
      </Show>

      <Stack gap="3">
        {items.map((item) => (
          <NavigationItem {...item} key={item.url} onClick={onSelected} />
        ))}
      </Stack>
    </Stack>
  )
})
