import {
  ArrowRightFromBracketSolidIcon,
  BarsSolidIcon,
  DollySolidIcon,
  XMarkSolidIcon,
} from '@app/components/icons'
import Logo from '@app/components/Logo'
import Navigation from '@app/components/Navigation'
import Spacer from '@app/components/Spacer'
import useAuth from '@app/hooks/use-auth.hook'
import ziggy from '@app/utils/ziggy'
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {Link, router} from '@inertiajs/react'
import {useRef} from 'react'

export interface MobileNavigationProps {
  user: User | AgencyMember
}

export default function MobileNavigation({user}: MobileNavigationProps) {
  const {
    isOpen: isNavigationOpen,
    onOpen: onOpenNavigation,
    onClose: onCloseNavigation,
  } = useDisclosure()
  const btnRef = useRef(null)

  const {isAdminDashboard} = useAuth()

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="white"
        paddingInline="4"
        height="62px"
        width="full"
      >
        <IconButton
          aria-label="Open menu"
          variant="ghost"
          ref={btnRef}
          onClick={onOpenNavigation}
          fontSize="lg"
        >
          {isNavigationOpen ? <XMarkSolidIcon /> : <BarsSolidIcon />}
        </IconButton>

        <Logo height="7" />
      </Flex>
      <Drawer
        isOpen={isNavigationOpen}
        placement="left"
        size="full"
        onClose={onCloseNavigation}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay
          style={{
            top: '62px',
          }}
        />
        <DrawerContent
          style={{
            top: '62px',
          }}
          paddingBlock="4"
        >
          <DrawerBody paddingBlock="0" paddingInline="6">
            <Stack
              backgroundColor="white"
              paddingInline="4"
              paddingBlock="4"
              border="1px solid"
              borderColor="gray.200"
              rounded="xl"
            >
              <Flex gap="4" paddingInline="2">
                <Avatar name={user.name} />

                <Stack spacing="0" flex="1">
                  <Text fontSize="sm" fontWeight="semibold">
                    {user.name}
                  </Text>
                  <Text fontSize="xs" textColor="gray.500">
                    {user.email}
                  </Text>
                </Stack>
              </Flex>

              <Divider />

              <Button
                aria-label="cerrar sesión"
                variant="ghost"
                paddingInline="2"
                paddingBlock="4"
                gap="4"
                textColor="gray.900"
                justifyContent="flex-start"
                fontSize="sm"
                as={Link}
                href={ziggy('pickup-orders.index')}
              >
                <Flex alignItems="center" gap="4">
                  <DollySolidIcon textColor="orange.600" />
                  <Text>Vista de bodega</Text>
                </Flex>
              </Button>
              <Button
                aria-label="cerrar sesión"
                variant="ghost"
                paddingInline="2"
                paddingBlock="4"
                gap="4"
                textColor="gray.900"
                justifyContent="flex-start"
                fontSize="sm"
                onClick={() => {
                  router.delete(
                    ziggy(isAdminDashboard ? 'admin.logout' : 'agency.logout')
                  )
                }}
                leftIcon={
                  <ArrowRightFromBracketSolidIcon textColor="orange.600" />
                }
              >
                Cerrar Sesión
              </Button>
            </Stack>

            <Spacer height="4" />
            <Navigation onSelected={onCloseNavigation} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
