import {type PageProps} from '@inertiajs/inertia'
import {usePage} from '@inertiajs/react'
import {useMemo} from 'react'

export type InertiaPageProps = PageProps & {
  viewer: AuthenticatedUser
}
export type AuthenticatedUser =
  | BaseAuthenticatedUser
  | BaseAuthenticatedAgencyMember

export type BaseAuthenticatedUser = {__typename: 'User'} & User

export type BaseAuthenticatedAgencyMember = {
  __typename: 'AgencyMember'
} & AgencyMember

export default function useAuth() {
  const {
    props: {viewer},
  } = usePage<InertiaPageProps>()

  const isAdminDashboard = useMemo(() => viewer.__typename === 'User', [viewer])

  const isOperator = useMemo(
    () => viewer.__typename === 'User' && viewer.role === 'operator',
    [viewer]
  )

  const isAgencyOwner = useMemo(
    () => viewer.__typename === 'AgencyMember' && viewer.is_agency_owner,
    [viewer]
  )

  const user = useMemo(
    () => ({
      ...viewer,
      agency: viewer.__typename === 'AgencyMember' ? viewer.agency : undefined,
    }),
    [viewer]
  )

  return {
    user,
    isAdminDashboard,
    isAgencyOwner,
    isOperator,
  }
}
