import type {ComponentType, LazyExoticComponent} from 'react'
import {createMachine, type InterpreterFrom} from 'xstate'

import {assign} from '@xstate/immer'

import type {OverlayProps} from './types'

interface OverlayManagerContext {
  component?: (
    props: OverlayProps
  ) => ReturnType<LazyExoticComponent<ComponentType<any>>>
}

type OverlayManagerEvent =
  | {
      type: 'CLOSE'
    }
  | {
      component(
        props: OverlayProps
      ): ReturnType<LazyExoticComponent<ComponentType<any>>>
      type: 'OPEN'
    }

export type OverlayManagerMachine = typeof machine

export type OverlayManagerInterpreter = InterpreterFrom<OverlayManagerMachine>

const machine = createMachine(
  {
    predictableActionArguments: true,
    schema: {
      context: {} as OverlayManagerContext,
      events: {} as OverlayManagerEvent,
    },
    tsTypes: {} as import('./overlay-manager.machine.typegen').Typegen0,
    id: 'overlays',
    initial: 'closed',
    context: {
      component: undefined,
    },
    states: {
      closed: {
        on: {
          OPEN: {
            actions: 'setComponent',
            target: 'opened',
          },
        },
      },

      closing: {
        after: {
          201: {
            actions: 'resetContext',
            target: 'closed',
          },
        },
      },

      opened: {
        on: {
          CLOSE: 'closing',
        },
      },
    },
  },
  {
    actions: {
      resetContext: assign((ctx) => {
        ctx.component = undefined
      }),
      setComponent: assign((ctx, e) => {
        ctx.component = e.component
      }),
    },
  }
)

export default machine
