import {Suspense} from 'react'

import {useSelector} from '@xstate/react'

import useOverlayManagerInterpreter from '@hooks/use-overlay-manager-interpreter.hook'

export default function OverlayManager() {
  const service = useOverlayManagerInterpreter()
  const component = useSelector(service, (state) => state.context.component)
  const isClosed = useSelector(service, (state) => state.matches('closed'))
  const isOpen = useSelector(service, (state) => state.matches('opened'))

  if (isClosed) {
    return null
  }

  const props = {
    isOpen,
    onClose: () => service.send('CLOSE'),
  }

  if (!component) {
    return null
  }

  return <Suspense fallback={null}>{component(props)}</Suspense>
}
