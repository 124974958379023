import {usePage} from '@inertiajs/react'

import {
  CommentsSolidIcon,
  CubesSolidIcon,
  DollarSignSolidIcon,
  FileChartColumnSolidIcon,
  HandHoldingBoxSolidIcon,
  HouseChimneySolidIcon,
  InboxIcon,
  MopedSolidIcon,
  PeopleGroupSolidIcon,
  UserShieldSolidIcon,
  UserSolidIcon,
  WrenchSolidIcon,
} from '@components/icons'

const ITEMS = {
  agencies: {
    icon: CubesSolidIcon,
  },
  customers: {
    icon: PeopleGroupSolidIcon,
  },
  dashboard: {
    icon: HouseChimneySolidIcon,
  },
  deliveries: {
    icon: MopedSolidIcon,
  },
  fees: {
    icon: DollarSignSolidIcon,
  },
  packages: {
    icon: HandHoldingBoxSolidIcon,
  },
  agency_fees: {
    icon: DollarSignSolidIcon,
  },
  reports: {
    icon: FileChartColumnSolidIcon,
  },
  agency_reports: {
    icon: CubesSolidIcon,
  },
  customer_reports: {
    icon: PeopleGroupSolidIcon,
  },
  agency_members: {
    icon: UserSolidIcon,
  },
  settings: {
    icon: WrenchSolidIcon,
  },
  users: {
    icon: UserShieldSolidIcon,
  },
  requests: {
    icon: InboxIcon,
  },
  tickets: {
    icon: CommentsSolidIcon,
  },
}

export interface NavigationItem {
  id: keyof typeof ITEMS
  title: string
  url: string
  items?: NavigationItem[]
  count?: number
}

export default function useNavigationItemsData() {
  const page = usePage()

  return (page.props.navigation_settings as NavigationItem[]).map((item) => {
    return {
      ...ITEMS[item.id],
      title: item.title,
      url: item.url,
      count: item.count,
      items: item.items?.map((subItem) => {
        return {
          ...ITEMS[subItem.id],
          title: subItem.title,
          url: subItem.url,
          count: subItem.count,
        }
      }),
    }
  })
}
