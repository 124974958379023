import Logo from '@app/components/Logo'
import {Flex, Show, Text} from '@chakra-ui/react'
import {Link} from '@inertiajs/react'

const links = [
  {
    label: 'Inicio',
    url: '/',
  },
  {
    label: 'Política de privacidad',
    url: '/privacy-policy',
  },
]

export interface HeaderProps {}

export default function Header(props: HeaderProps) {
  return (
    <Flex
      alignItems="center"
      as="header"
      height="20"
      justifyContent="space-between"
    >
      <Logo height="8" />

      <Show above="md">
        <Flex>
          {links.map((link) => (
            <Text
              key={link.url}
              as={Link}
              href={link.url}
              fontSize="sm"
              fontWeight="semibold"
              padding="4"
              textColor={
                window.location.pathname === link.url
                  ? 'orange.700'
                  : 'gray.500'
              }
            >
              {link.label}
            </Text>
          ))}
        </Flex>
      </Show>
    </Flex>
  )
}
