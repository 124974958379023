import {
  toast as reactHotToast,
  type ToastType,
  type ToastOptions as ReactHotToastOptions,
} from 'react-hot-toast/headless'

export type CustomToastOptions = {
  title: string
  description?: string
  type?: Exclude<ToastType, 'blank'>
  progress?: number
  action?: {
    label: string
    onClick: () => void
  }
}

export type ToastOptions = ReactHotToastOptions & CustomToastOptions

export function sendToast({type, title, ...opts}: ToastOptions) {
  reactHotToast[type ?? 'success'](title, {
    ...opts,
  })
}

export function closeToast(id: string) {
  reactHotToast.remove(id)
}
