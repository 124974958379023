import {useEffect, useMemo} from 'react'
import type {ArrayOrSingle, BroadcastEvent} from '@app/index'
import {captureException} from '@sentry/react'
import usePlayAudio from './use-play-audio'
import useTextToSpeech from './use-text-to-speech.hook'
import {defaultAlertTone} from '@app/constants/notification'
import {useDebouncedCallback} from 'use-debounce'

type UseRegisterChannelEventsConfig = ArrayOrSingle<{
  channel: string
  onError?: (error: any) => void
  onSubscribed?: () => void
  events: Readonly<BroadcastEvent[]>
}>

export default function useRegisterChannelEvents(
  config: UseRegisterChannelEventsConfig
) {
  const channels = useMemo(
    () => (Array.isArray(config) ? config : [config]),
    [config]
  )

  const {playAudio} = usePlayAudio()
  const {speak} = useTextToSpeech()

  const notify = useDebouncedCallback((msg: string, soundSrc?: string) => {
    if (
      !playAudio(soundSrc ?? defaultAlertTone, () => {
        speak(msg)
      })
    ) {
      speak(msg)
    }
  }, 200)

  useEffect(() => {
    channels.forEach(({channel: channelName, events}) => {
      const channel = window.Echo.channel(channelName).error((error: any) => {
        captureException(error)
      })

      events.forEach(({name, callback}) => {
        channel.listen(name, (args: any) => callback({notify, ...args}))
      })
    })

    return () => {
      channels.forEach(({channel}) => {
        window.Echo.leave(channel)
      })
    }
  }, [channels, notify])
}
