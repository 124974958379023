import {Box, Show} from '@chakra-ui/react'
import Navigation from '../Navigation'
import MobileNavigation from './components/MobileNavigation'
import useAuth from '@app/hooks/use-auth.hook'
import userChannelEvents from '@app/channels/events/userChannelEvents'
import deliveriesChannelEvents from '@app/channels/events/deliveriesChannelEvents'
import useRegisterChannelEvents from '@app/hooks/use-register-channel-events-hook'

export interface DashboardLayoutProps {
  children: React.ReactNode
}

export default function DashboardLayout({children}: DashboardLayoutProps) {
  const {isAdminDashboard, user} = useAuth()

  useRegisterChannelEvents(
    isAdminDashboard
      ? [
          {
            channel: `App.Models.User.${user!.id}`,
            events: userChannelEvents,
          },
          {
            channel: `deliveries`,
            events: deliveriesChannelEvents,
          },
        ]
      : [
          {
            channel: `App.Models.AgencyMember.${user!.id}`,
            events: userChannelEvents,
          },
        ]
  )

  return (
    <Box
      paddingBlockStart={{
        xl: 4,
      }}
      paddingInline={{
        xl: 4,
      }}
      paddingBlockEnd={8}
      gap="8"
      display={{
        xl: 'flex',
      }}
      maxWidth={{xl: 'container.2xl'}}
      alignItems="flex-start"
      marginInline="auto"
      position="relative"
    >
      <Show below="xl">
        <MobileNavigation user={user!} />
      </Show>
      <Box position="sticky" minWidth="280px" maxWidth="280px" top="1">
        <Show above="xl">
          <Navigation />
        </Show>
      </Box>
      <Box flex="1" overflow="hidden">
        {children}
      </Box>
    </Box>
  )
}
