import {StrictMode, useMemo} from 'react'
import {createRoot} from 'react-dom/client'

import {ChakraProvider} from '@chakra-ui/react'
import {createInertiaApp} from '@inertiajs/react'

import theme from '@app/theme'
import ErrorBoundary from '@components/ErrorBoundary'
import OverlayManager from '@components/OverlayManager'
import Toaster from '@components/Toaster'

import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import * as Sentry from '@sentry/react'
import DashboardLayout from '@components/DashboardLayout/DashboardLayout'
import PageLayout from './components/PageLayout/PageLayout'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_REACT_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
})

window.Pusher = Pusher

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  wsHost: import.meta.env.VITE_PUSHER_HOST,
  wsPort: import.meta.env.VITE_PUSHER_PORT,
  wssPort: import.meta.env.VITE_PUSHER_PORT,
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
})

const dashboardPrefixes = ['/admin', '/agency']

const pagesWithoutLayout = [
  '/login',
  '/password',
  '/forgot-password',
  '/admin/pickup-orders',
]

createInertiaApp({
  resolve(name) {
    const sections = import.meta.glob('./sections/**/entry.ts')

    return sections[`./sections/${name}/entry.ts`]()
  },
  setup({el, App, props}) {
    createRoot(el).render(
      <StrictMode>
        <ChakraProvider theme={theme}>
          <App {...props}>
            {({Component, key, props}) => {
              const Layout = useMemo(
                () => getLayout(!!props.viewer),
                [window.location.pathname, props.viewer] // eslint-disable-line react-hooks/exhaustive-deps
              )

              return (
                <>
                  <Layout>
                    <Component key={key} {...props} />
                  </Layout>
                  <ErrorBoundary FallbackComponent={() => null}>
                    <OverlayManager />
                  </ErrorBoundary>
                  <Toaster />
                </>
              )
            }}
          </App>
        </ChakraProvider>
      </StrictMode>
    )
  },
})

function getLayout(hasViewer = false) {
  const isDashboard = dashboardPrefixes.some((prefix) =>
    window.location.pathname.startsWith(prefix)
  )

  const withoutLayout = pagesWithoutLayout.some(
    (page) =>
      window.location.pathname === page ||
      window.location.pathname.startsWith(page)
  )

  if (isDashboard && hasViewer && !withoutLayout) {
    return DashboardLayout
  } else if (!withoutLayout && !isDashboard) {
    return PageLayout
  }

  return ({children}: {children: React.ReactNode}) => <>{children}</>
}
