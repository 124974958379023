import spacing from './spacing'

const container = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
}

export default {
  ...spacing,
  'h-screen': '100vh',
  'w-screen': '100vw',
  container,
} as const
