import {Box, Container, Stack} from '@chakra-ui/react'
import Footer from './components/Footer'
import Header from './components/Header'

export interface PageLayoutProps {
  children: React.ReactNode
}

export default function PageLayout(props: PageLayoutProps) {
  return (
    <>
      <Container
        as={Stack}
        sx={{
          '[id]': {
            scrollMarginTop: '28',
          },
        }}
        padding={{
          base: '8',
          md: '8',
        }}
      >
        <Header />
        <Box>{props.children}</Box>
      </Container>
      <Footer />
    </>
  )
}
