import {captureException} from '@sentry/react'
import {useCallback} from 'react'

export default function useTextToSpeech() {
  const speak = useCallback((text: string) => {
    const voice =
      speechSynthesis
        .getVoices()
        .filter((voice) => voice.lang === 'es-MX')
        .find((voice) => voice.name === 'Paulina') ??
      speechSynthesis.getVoices()[0]

    const utterance = new SpeechSynthesisUtterance(text)
    utterance.voice = voice

    utterance.lang = 'es-MX'
    utterance.volume = 1

    try {
      speechSynthesis.speak(utterance)
    } catch (e) {
      console.warn(e)
      captureException(e)
    }
  }, [])

  return {speak}
}
