import {
  FacebookBrandIcon,
  InstagramBrandIcon,
  WhatsAppBrandIcon,
} from '@app/components/icons'
import Logo from '@app/components/Logo'
import {Box, Center, Flex, IconButton, Text} from '@chakra-ui/react'

const SOCIAL_LINKS = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/1clicknicaragua',
    icon: FacebookBrandIcon,
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/1clicknicaragua',
    icon: InstagramBrandIcon,
  },
  {
    name: 'Whatsapp',
    href: 'https://api.whatsapp.com/send?phone=%2B50585754255',
    icon: WhatsAppBrandIcon,
  },
]

export default function Footer() {
  return (
    <Center
      paddingBlock="12"
      borderTopWidth="1px"
      height={{
        base: 'auto',
        md: '15%',
      }}
    >
      <Flex
        width="container.lg"
        justifyContent="space-between"
        marginInline="auto"
        gap="4"
        flexDir={{base: 'column', md: 'row'}}
        alignItems="center"
        paddingBlock={{base: '8', lg: '0'}}
      >
        <Box width="80px">
          <Logo />
        </Box>

        <Text fontSize="sm" color="gray.500">
          © 2023 <a href="https://1clicknicaragua.com">1Click Nicaragua</a>. All
          rights reserved.
        </Text>

        <Flex justifyContent="center" gap="2">
          {SOCIAL_LINKS.map(({name, href, icon: Icon}) => (
            <IconButton
              key={name}
              aria-label={name}
              as="a"
              href={href}
              variant="ghost"
            >
              <Icon boxSize="6" />
            </IconButton>
          ))}
        </Flex>
      </Flex>
    </Center>
  )
}
