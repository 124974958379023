import {defineStyle, defineStyleConfig} from '@chakra-ui/styled-system'

const baseStyle = defineStyle({
  borderRadius: 'lg',
})

const defaultProps = {
  variant: 'solid',
  colorScheme: 'slate',
}

const sizes = {
  md: {
    paddingInline: {
      base: '8',
    },
    paddingBlock: {
      base: '22px',
    },
    fontSize: {
      base: 'xs',
    },
  },
  lg: {
    fontSize: {
      base: 'sm',
    },
  },
}

export const buttonTheme = defineStyleConfig({
  baseStyle,
  defaultProps,
  sizes,
})
