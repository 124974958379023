import {AnimatePresence} from 'framer-motion'
import {useToaster, type Toast} from 'react-hot-toast/headless'

import {Stack} from '@chakra-ui/react'

import {type CustomToastOptions} from '@app/utils/toast'
import CustomToast from './components/Toast'

export default function Toaster() {
  const {
    toasts,
    handlers: {endPause, startPause},
  } = useToaster({
    success: {
      duration: 4000,
    },
  }) as Omit<ReturnType<typeof useToaster>, 'toasts'> & {
    toasts: Array<Toast & CustomToastOptions>
  }

  return (
    <Stack
      alignItems="flex-end"
      insetBlockEnd="6"
      insetInline="6"
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      position="fixed"
      spacing="2"
      zIndex="toast"
    >
      <AnimatePresence>
        {toasts
          .filter((toast) => toast.visible)
          .reverse()
          .map((toast) => {
            return <CustomToast key={toast.id} {...toast} />
          })}
      </AnimatePresence>
    </Stack>
  )
}
