import {captureException} from '@sentry/react'
import {useCallback} from 'react'

export default function usePlayAudio() {
  const playAudio = useCallback(
    (audioSrc: string, onEndAudio?: () => any): boolean => {
      try {
        const audio = new Audio(audioSrc)

        audio.onended = () => {
          if (onEndAudio) {
            onEndAudio()
          }
        }

        let result = audio.play()

        if (result !== undefined) {
          result
            .then(() => {})
            .catch((error) => {
              captureException(error)
              console.error(error)

              audio.oncanplaythrough = () => {
                audio.play()
              }
            })
        }
      } catch (e) {
        captureException(e)
      }

      return true
    },
    []
  )

  return {playAudio}
}
