import {Box, Center, Flex} from '@chakra-ui/react'
import Link from '@components/Link'

import NavigationItemIcon from './NavigationItemIcon'

export interface NavigationSubItemProps {
  title: string
  icon: React.ElementType
  url: string
  isActive?: boolean
  count?: number
  onClick?: () => void
}

export default function NavigationSubItem({
  title,
  icon,
  url,
  isActive,
  count,
  onClick,
}: NavigationSubItemProps) {
  return (
    <Flex
      alignItems="center"
      as={Link}
      borderRadius="lg"
      className={isActive ? 'navigation-item-active' : undefined}
      height="12"
      href={url}
      paddingInline="4"
      role="group"
      sx={{
        '&.navigation-item-active': {
          backgroundColor: {
            base: 'orange.100',
            xl: 'white',
          },
          boxShadow: {
            base: 'none',
            xl: 'base',
          },
        },
      }}
      _hover={{
        backgroundColor: 'white',
        boxShadow: 'sm',
        textDecoration: 'none',
      }}
      onClick={onClick}
    >
      <NavigationItemIcon icon={icon} isSubItem />
      <Flex flex="1">
        <Box
          color={isActive ? 'gray.800' : 'gray.500'}
          fontSize="sm"
          fontWeight="semibold"
          letterSpacing="tight"
        >
          {title}
        </Box>

        {!!count && (
          <Center
            marginInlineStart="auto"
            borderColor="gray.300"
            borderWidth="1px"
            color="black"
            fontWeight="semibold"
            paddingInline="2"
            rounded="xl"
            fontSize="10px"
          >
            {count}
          </Center>
        )}
      </Flex>
    </Flex>
  )
}
