import {sendToast, closeToast} from '@app/utils/toast'
import ziggy from '@app/utils/ziggy'
import {BroadcastEventCallbackArgs, type BroadcastEvent} from '@app/index'

export default [
  {
    name: '.package-report-file-status',
    callback: ({
      status,
      progress,
      filename,
    }: BroadcastEventCallbackArgs<{
      status: 'success' | 'pending' | 'error'
      progress: number
      filename: string
    }>) => {
      if (status !== 'pending') {
        const isSuccessful = status === 'success'
        closeToast(filename)

        sendToast({
          id: `download-${filename}-toast`,
          type: isSuccessful ? 'success' : 'error',
          duration: isSuccessful ? Infinity : 5000,
          title: isSuccessful ? 'Éxito' : 'Error',
          description: isSuccessful
            ? `Se genero el reporte y esta listo para descargar`
            : 'Ocurrió un error al generar el reporte',

          position: 'top-right',
          ...(isSuccessful && {
            style: {
              border: '1px solid #cccccc',
              background: '#ffffff',
              color: '#000000',
            },
            action: {
              onClick: () => {
                window.open(
                  ziggy('reports.download', {filename: filename}),
                  '_blank'
                )
              },
              label: 'Descargar',
            },
          }),
        })
      } else {
        sendToast({
          id: filename,
          title: 'Generando reporte',
          type: 'loading',
          progress: progress,
        })
      }
    },
  },
] as ReadonlyArray<BroadcastEvent>
