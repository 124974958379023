import {buttonTheme} from './button'
import {containerTheme} from './container'
import {linkTheme} from './link'
import {menuTheme} from './menu'

export {containerTheme as Container} from './container'
export {buttonTheme as Button} from './button'
export {linkTheme as Link} from './link'
export {menuTheme as Menu} from './menu'

export default {
  Button: buttonTheme,
  Container: containerTheme,
  Link: linkTheme,
  Menu: menuTheme,
} as const
