import {
  Box,
  Center,
  Collapse,
  Flex,
  IconButton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'

import Link from '@components/Link'
import useNavigationItemsData from './hooks/use-navigation-items-data.hook'
import NavigationSubItem from './NavigationSubItem'
import NavigationItemIcon from './NavigationItemIcon'
import {ChevronDownSolidIcon} from '../icons'

export default function NavigationItem({
  icon,
  title,
  url,
  items = [],
  count,
  onClick,
}: ReturnType<typeof useNavigationItemsData>[0] & {
  onClick?: () => void
}) {
  const isActive =
    !items.length && window.location.pathname.startsWith(new URL(url).pathname)

  const {isOpen, onToggle} = useDisclosure({
    defaultIsOpen: !!items.length,
  })

  return (
    <>
      <Flex
        alignItems="center"
        as={Link}
        borderRadius="lg"
        className={isActive ? 'navigation-item-active' : undefined}
        height="12"
        href={url}
        paddingInline="4"
        role="group"
        sx={{
          '&.navigation-item-active': {
            backgroundColor: !items.length
              ? {
                  base: 'orange.50',
                  xl: 'white',
                }
              : undefined,
            boxShadow: {
              base: 'none',
              xl: 'sm',
            },
          },
        }}
        _hover={
          items.length
            ? {
                textDecoration: 'none',
              }
            : {
                backgroundColor: 'white',
                textDecoration: 'none',
              }
        }
        onClick={onClick}
      >
        <NavigationItemIcon icon={icon} />
        <Box
          color={isActive ? 'gray.800' : 'gray.500'}
          fontSize="sm"
          fontWeight="semibold"
          letterSpacing="tight"
        >
          {title}
        </Box>
        {!!count && (
          <Center
            marginInlineStart="auto"
            backgroundColor="red.500"
            color="white"
            paddingInline="1.5"
            rounded="full"
            fontSize="xs"
            fontWeight="semibold"
          >
            {`+${count}`}
          </Center>
        )}

        {items.length > 0 && (
          <IconButton
            aria-label={title}
            size="xs"
            marginInlineStart="auto"
            variant="ghost"
            onClick={(e) => {
              e.preventDefault()
              onToggle()
            }}
          >
            <ChevronDownSolidIcon
              sx={{
                transform: isOpen ? 'rotate(180deg)' : undefined,
              }}
            />
          </IconButton>
        )}
      </Flex>
      {items.length > 0 && (
        <Collapse in={isOpen} animateOpacity>
          <Stack paddingInlineStart="10" spacing="2" paddingBlock="1">
            {items.map((item) => (
              <NavigationSubItem
                {...item}
                onClick={onClick}
                key={item.url}
                isActive={
                  window.location.pathname === new URL(item.url).pathname
                }
              />
            ))}
          </Stack>
        </Collapse>
      )}
    </>
  )
}
