import {Center, Spacer} from '@chakra-ui/react'
import * as React from 'react'
import {isValidElementType} from 'react-is'

export interface NavigationItemIconProps {
  icon: React.ElementType
  isSubItem?: boolean
}

export default function NavigationItemIcon({
  icon: Icon,
  isSubItem = false,
}: NavigationItemIconProps) {
  return isValidElementType(Icon) ? (
    <>
      <Center
        backgroundColor="white"
        borderRadius="lg"
        boxSize="32px"
        color="orange.500"
        _groupHover={{
          boxShadow: 'none',
        }}
      >
        <Icon />
      </Center>
      <Spacer maxWidth="3" />
    </>
  ) : null
}
