import {menuAnatomy as parts} from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system'

const {defineMultiStyleConfig, definePartsStyle} =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyleItem = defineStyle({
  borderRadius: 'base',
})

const baseStyleList = defineStyle({
  paddingInline: '2',
})

const baseStyle = definePartsStyle({
  item: baseStyleItem,
  list: baseStyleList,
})

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
})
