import {
  CircleCheckRegularIcon,
  CircleExclamationSolidIcon,
  HourglassHalfSolidIcon,
} from '@app/components/icons'
import {CustomToastOptions} from '@app/utils/toast'
import {
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  Progress,
  Stack,
  Text,
} from '@chakra-ui/react'
import {motion} from 'framer-motion'

import toast, {
  Toast as ToastOptions,
  ToastType,
  resolveValue,
} from 'react-hot-toast/headless'

export type ToastProps = ToastOptions & CustomToastOptions

export default function Toast({...opts}: ToastProps) {
  const variantProps = getVariantProps(opts.type)

  return (
    <Box
      {...opts.ariaProps}
      as={motion.div}
      animate={{
        opacity: 1,
        scale: 1,
        y: 0,
      }}
      boxShadow="0 2px 10px hsl(0 0% 0% / 0.25)"
      exit={{
        opacity: 0,
        scale: 0.5,
        transition: {
          duration: 0.2,
        },
      }}
      position="relative"
      fontSize="sm"
      initial={{
        opacity: 0,
        scale: 0.3,
        y: 50,
      }}
      style={{
        ...opts.style,
      }}
      backgroundColor="white"
      borderRadius="xl"
      padding="4"
      border="1px solid"
      borderColor="gray.200"
      marginBottom="4"
      width="96"
    >
      <Flex
        gap="4"
        alignItems={opts.description || opts.action ? 'flex-start' : 'center'}
        marginInlineEnd="2"
      >
        <Center
          border="1px solid"
          borderColor="gray.200"
          rounded="lg"
          boxSize="8"
          minWidth="8"
          boxShadow="sm"
          backgroundColor={`${variantProps?.colorScheme}.600`}
          color="white"
        >
          {variantProps?.icon || <CircleCheckRegularIcon boxSize="5" />}
        </Center>
        <Stack fontSize="sm" spacing="1" letterSpacing="tight" flex="1">
          <Text fontWeight="bold">{resolveValue(opts.message, opts)}</Text>
          {opts.description && (
            <Text lineHeight="shorter" color="gray.600" noOfLines={3}>
              {opts.description}
            </Text>
          )}

          {opts.type === 'loading' && (
            <Stack gap="2" alignItems="end">
              <Progress
                isIndeterminate={!opts.progress}
                colorScheme="orange"
                size="sm"
                rounded="md"
                width="full"
                marginBlockStart="3"
                value={opts.progress}
              />
              <Text fontSize="xs" fontWeight="semibold" color="gray.600">
                {Math.floor(opts.progress ?? 0).toString() + '%'}
              </Text>
            </Stack>
          )}
          {opts.action && (
            <Button
              marginBlockStart="4"
              alignSelf="start"
              variant="link"
              onClick={() => {
                opts.action?.onClick()
                toast.remove(opts.id)
              }}
              colorScheme={variantProps?.colorScheme || 'blue'}
            >
              {opts.action.label}
            </Button>
          )}
          {/* <ButtonGroup variant="link" marginBlockStart="4" paddingInline="0"> */}
          {/* </ButtonGroup> */}
        </Stack>
        <CloseButton
          position="absolute"
          insetInlineEnd="2.5"
          insetBlockStart="2.5"
          marginInlineStart="auto"
          alignSelf={opts.description || opts.action ? 'flex-start' : 'center'}
          color="gray.600"
          fontSize="10px"
          boxSize="5"
          onClick={() => toast.remove(opts.id)}
        />
      </Flex>
    </Box>
  )
}

function getVariantProps(type: ToastType) {
  switch (type) {
    case 'loading':
      return {
        icon: <HourglassHalfSolidIcon />,
        colorScheme: 'orange',
      }
    case 'success':
      return {icon: <CircleCheckRegularIcon />, colorScheme: 'teal'}
    case 'error':
      return {icon: <CircleExclamationSolidIcon />, colorScheme: 'red'}
    default:
      return null
  }
}
