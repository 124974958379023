import {useCallback, type ReactNode} from 'react'
import {
  ErrorBoundary as BaseErrorBoundary,
  type ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary'

import {callAll} from '@chakra-ui/utils'

// import {captureException} from '@utils/analytics/sentry'

interface ErrorBoundaryProps extends ErrorBoundaryPropsWithComponent {
  children: ReactNode
}

export default function ErrorBoundary({
  onError: _onError,
  ...props
}: ErrorBoundaryProps) {
  const onError = useCallback((error: Error) => {
    console.error(error)
    // captureException(error)
  }, [])

  return <BaseErrorBoundary {...props} onError={callAll(onError, _onError)} />
}
