import breakpoints from './breakpoints'
import colors from './colors'
import sizes from './sizes'
import spacing from './spacing'
import typography from './typography'

export default {
  breakpoints,
  colors,
  sizes,
  space: spacing,
  ...typography,
} as const
